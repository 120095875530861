import { UserResponseType } from 'typings/userTypes';
import axios from 'axios';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export async function GetUserDetails(): Promise<ApiResponse<UserResponseType>> {
  const response = await axios.get('/api/v1/parents');
  return response.data;
}

type DeleteAccountPayload = {
  email: string;
};

export async function deleteAccountServices(
  payload: DeleteAccountPayload,
  partnerName: string
) {
  const response = await axios.delete(endpoints.requestAccountDelete, {
    headers: {
      'Partner-ID': partnerName,
      'Client-ID': 'web'
    },
    data: payload
  });
  return response;
}
