/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import styled from 'styled-components';
import { FormEvent, useEffect, useState } from 'react';
import { Button, styled as styledMUI } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  SubscriptionType,
  CheckFreeTrialSubscription,
  GetSubscriptionDetails
} from 'services/api/subscriptionApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { endpoints } from 'services/api/endpoints';
import CircularProgress from '@mui/material/CircularProgress';

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
`;

const BottomContainer = styledMUI('div')(({ theme }) => ({
  width: '100%',
  marginBottom: '90px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end'
  }
}));

const Heading = styled.div`
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
`;
const SubHeading = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #484c4f;
`;

const FormContainer = styledMUI('form')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '360px'
  }
}));

export interface DeviceInfo {
  device_id: string;
  password: string;
  infant_name: string;
  image_url: string;
  sku_id: string;
  is_paired: boolean;
  is_activated: boolean;
}

function SetupInit() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  //   const spaceId = query.get('space_id') || '';
  const deviceId = query.get('device_id') || '';
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionType[]>(
    []
  );

  const [isEligibleForTrial, setIsEligibleForTrial] = useState(false);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log('subscriptionData', subscriptionData);
  const dispatch = useAppDispatch();

  const authenticateDevice = async () => {
    // debugger;

    const result = await axios.patch(endpoints.updateDevices(deviceId), {
      is_activated: true
    });
    // debugger;

    console.log('authenticateDevice', result);
  };

  const fetchSubscriptionInfo = async () => {
    try {
      setIsLoading(true);
      const response = await GetSubscriptionDetails();
      setSubscriptionData(response.data.subscriptions);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // debugger;
    authenticateDevice();
    // debugger;
    fetchSubscriptionInfo();
    axios
      .get('/api/v1/devices')
      .then((res) => {
        // debugger;
        console.log('res', res.data.data.devices);
      })
      .catch((err) => {
        console.log('err', err);
      });

    CheckFreeTrialSubscription()
      .then((res) => {
        setIsEligibleForTrial(res.data.trial);
      })
      .catch((error) => {
        console.log('Free trial api call failed: ', error);
      });
  }, []);

  //   const getSubscriptionFromDeviceId = () => {
  //     debugger;
  //     const data = subscriptionData.filter((subscriptionItem) => {
  //         debugger;
  //       if (!subscriptionItem.devices.length) return false;

  //         debugger;
  //       return subscriptionItem.devices.filter((deviceItem) => {
  //         debugger;
  //         return deviceItem.id === deviceId;
  //       });
  //     });

  //     debugger;
  //     if(data.length){
  //         return data[0];
  //     }
  //     return false
  //   };

  const getSupportedCameraCount = (supportedFeatures: any) => {
    const cameraSupportedCount = supportedFeatures
      .find((item: any) => item.id.includes('camera_supported'))
      ?.id.split('_')[0];

    return cameraSupportedCount === 'unlimited' ? 99 : 1;
  };

  const getSubscritionForAdditionalCamera = () => {
    // debugger;
    return subscriptionData.find((subscriptionItem) => {
      //   debugger;
      const supportedCameraCount = getSupportedCameraCount(
        subscriptionItem.supported_features
      );
      //   debugger;

      return (
        subscriptionItem.subscribe_status === 'Active' &&
        subscriptionItem.devices.length &&
        supportedCameraCount > subscriptionItem.devices.length
      );
    });
  };

  const getSubscritionForRepairedCamera = () => {
    // debugger;
    return subscriptionData.find((subscriptionItem) => {
      //   debugger;
      return (
        !subscriptionItem.devices.length &&
        subscriptionItem.subscribe_status === 'Active'
      );
    });
  };

  const addCameraToSubscription = async (extsubscription: any) => {
    // debugger;

    const deviceArr = extsubscription.devices.map((el: any) => el?.id);
    deviceArr.push(deviceId);

    const payload = {
      device_ids: deviceArr
    };
    // debugger;

    try {
      const subscriptionId = extsubscription.purchase_id;
      //   debugger;

      const res = await axios.post(
        `${endpoints.subscriptionUrl}/${subscriptionId}/devices`,
        payload
      );
      console.log('addCameraToSubscription', res);
      //   debugger;

      dispatch(fetchAllSubscription());

      navigate('/payment-success', {
        state: {
          isFreeTrial: isEligibleForTrial,
          isUpgrade: false,
          errorMessage: '',
          showSetupComplete: Boolean(deviceId),
          deviceId,
          purchaseId: subscriptionId
        }
      });
    } catch (err) {
      console.log('addCameraToSubscription', err);
    }
  };

  const sendUserToPurchaseSubscription = () => {
    // debugger;
    navigate('/why-subscribe', {
      replace: true,
      state: {
        isFreeTrial: isEligibleForTrial,
        isUpgrade: false,
        deviceId,
        pairingFlow: true
      }
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setIsSubmiting(true);
      //   debugger;
      // if no subscriptions present means its a fresh setup
      if (subscriptionData?.length === 0) {
        // debugger;
        sendUserToPurchaseSubscription();
      }

      // if subscriptions are there, it means its either a additional camera or repair an existing camera
      if (subscriptionData.length > 0) {
        // debugger;
        // iterate through subscriptions and check if any existing active subscription exists for repaired camera
        const existingSubscriptionForRepairedCamera =
          getSubscritionForRepairedCamera();
        // debugger;
        if (existingSubscriptionForRepairedCamera) {
          addCameraToSubscription(existingSubscriptionForRepairedCamera);
          return;
        }

        // iterate through subscriptions and check if additional camera can be added to existing subscription
        const existingSubscriptionForAdditionalCamera =
          getSubscritionForAdditionalCamera();
        // debugger;
        if (existingSubscriptionForAdditionalCamera) {
          addCameraToSubscription(existingSubscriptionForAdditionalCamera);
          return;
        }

        // if not send user to purchase a new subscription
        // debugger;
        sendUserToPurchaseSubscription();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return isLoading ? (
    <CircularProgress />
  ) : (
    <FormContainer onSubmit={handleSubmit}>
      <TopContainer>
        <Heading>
          Congratulations on setting up your camera! You are just a few steps
          from finishing the pairing process.
        </Heading>
        <SubHeading>
          Please click continue to finish the pairing process.
        </SubHeading>
      </TopContainer>
      {isSubmiting && <CircularProgress />}
      <BottomContainer>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            justifyContent: 'center',
            outline: 'none',
            '&:focus': {
              outline: 'none'
            }
          }}
          style={{
            maxWidth: '300px',
            borderRadius: '44px',
            outline: 'none'
          }}
          disabled={isSubmiting}
        >
          Continue
        </Button>
      </BottomContainer>
    </FormContainer>
  );
}
export default SetupInit;
